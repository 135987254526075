// 文章收藏
<template>
  <div class="sld_article_list">
    <!-- <MemberTitle :memberTitle="L['文章收藏']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["文章收藏"] }}</h3>
      <template v-if="article.data.length > 0">
        <template v-for="item in article.data" :key="item">
          <div class="article_item">
            <div class="article_right">
              <div class="article_top" @click="detail(item.informationId)">
                <div class="article_title">{{item.title}}</div>
                <div class="article_desc">{{item.introduction}}</div>
              </div>
              <div class="article_bottom">
                <span class="article_store">{{item.storeName}}</span>
                <span class="article_time">{{item.createTime}}</span>
                <div class="article_cancle" @click="cancle(item.informationId)">取消收藏</div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <SldCommonEmpty v-else totalWidth="1003" tip="暂无文章数据" />
    </div>
    <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current" :page-size="pageData.pageSize"
      layout="prev, pager, next, jumper" :total="pageData.total" :hide-on-single-page="false"
      class="flex_row_end_center" v-if="article.data.length > 0"></el-pagination>
  </div>
</template>

<script>
  import { ref, reactive, getCurrentInstance, onMounted } from "vue";
  import { useRouter } from 'vue-router';
  import MemberTitle from "@/components/MemberTitle";
  import SldCommonEmpty from '@/components/SldCommonEmpty'
  import { ElMessage } from 'element-plus';
  export default {
    name: "memberArticleCollect",
    components: {
      MemberTitle,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const article = reactive({ data: [] });
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0
      });

      const articleList = () => { //获取收藏文章列表
        let param = {
          current: pageData.current,
          pageSize: pageData.pageSize
        }
        proxy
          .$get('v3/cms/front/information/memberCollectInformationList', param)
          .then(res => {
            article.data = res.data.list;
            pageData.total = res.data.pagination.total;
          })
      };

      const detail = (id) => { //查看文章详情
        router.push({
          path: '/information/detail',
          query: {
            infoId: id
          }
        })
      };

      const cancle = (id) => { //取消收藏
        proxy.$confirm('确定取消收藏吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {
            informationId: id,
            state: false //true-收藏,false-取消收藏
          }
          proxy
            .$post('v3/cms/front/information/memberCollectInformation', param)
            .then(res => {
              if (res.state == 200) {
                ElMessage.success('取消收藏成功')
                articleList();
              } else {
                ElMessage.error('取消收藏失败')
              }
            })
        })
      };

      //页数改变
      const handleCurrentChange = e => {
        pageData.current = Math.floor(e);
        articleList();
      };

      onMounted(() => {
        articleList();
      })

      return {
        L,
        pageData,
        article,
        articleList,
        detail,
        cancle,
        handleCurrentChange,
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/style/member/articleCollect.scss";
</style>
<style lang="scss">
  .sld_article_list {
    .el-pagination {
      background-color: #FFFFFF;
      padding-right: 20px;
      padding-bottom: 15px;
    }
  }
</style>